import React from "react";
import { Config } from "../config";
import { Note } from "../../models/note";

type Props = {
  timestamp: number;
  className: string;
};

const DateString: React.FC<Props> = ({ timestamp, className="textbox-archive-date"}) => {
  const formattedDate = new Date(timestamp).toLocaleString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  });
  return <span className={className}>{formattedDate}</span>;
};

export default DateString;
