import React, { useRef, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import DateString from "./dateString";

import "./noteEditor.css";
import Button from "./button";

interface Props {
  save: (content: string) => Promise<boolean>;
  currentDate: number;
}

const NoteEditor: React.FC<Props> = ({ save, currentDate }) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [showWarning, setShowWarning] = useState(false);

  const handleSave = async () => {
    if (textAreaRef.current) {
      const content = textAreaRef.current.value;
      if (content != "") {
        if (await save(content)) {
          textAreaRef.current.value = "";
          textAreaRef.current.focus();
          setShowWarning(false);
        } else {
          setShowWarning(true);
        }
      }
    }
  };

  const handleWarningClose = () => {
    setShowWarning(false);
  };

  return (
    <>
      <form key={"editor"} className="note-wrapper">
        <DateString
          className="note-date"
          key={"date"}
          timestamp={currentDate}
        />
        <TextareaAutosize
          cols={2000}
          key="entry-area"
          placeholder="Record your thoughts..."
          autoFocus
          autoCorrect="on"
          autoComplete="off"
          autoCapitalize="off"
          className="note-content editor"
          onKeyDown={(e: React.KeyboardEvent<HTMLTextAreaElement>) => {
            if (e.code == "Enter" && (e.metaKey || e.ctrlKey)) {
              handleSave();
            }
          }}
          ref={textAreaRef}
        />
        {showWarning && (
          <div className="warning-message">
            🚨 Failed to save! Try again? 🤞
            <button onClick={handleWarningClose}>x</button>
          </div>
        )}
        <Button
          onClick={(e) => {
            e.preventDefault();
            handleSave();
          }}
        >
          Submit
        </Button>
      </form>
    </>
  );
};

export default NoteEditor;
