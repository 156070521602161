import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Sling as Hamburger } from "hamburger-react";
import "./nav.css";

const NavLinkList = ({ onClick, isLoggedIn }) => (
  <>
    <li>
      <Link className="shine-effect-hover" onClick={onClick} to="/">
        Home
      </Link>
    </li>
    {isLoggedIn && (
      <li>
        <Link className="shine-effect-hover" onClick={onClick} to="/profile">
          Profile
        </Link>
      </li>
    )}
    <li>
      <Link className="shine-effect-hover" onClick={onClick} to="/about">
        About
      </Link>
    </li>
  </>
);

export const Nav = ({ isLoggedIn }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navRef = useRef<HTMLDivElement>(null);
  const hamburgerColor = getComputedStyle(document.documentElement)
    .getPropertyValue("--second-color")
    .trim();

  const handleMenuClick = () => setIsMenuOpen(!isMenuOpen);

  const handleClickOutside = (event) => {
    if (navRef.current && !navRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  return (
    <div>
      <nav ref={navRef}>
        <Link className="title-link" to="/">
          <h1 className="title shine-effect-hover">Chronofile</h1>
        </Link>

        <div className="links-container">
          <NavLinkList onClick={handleMenuClick} isLoggedIn={isLoggedIn} />
        </div>

        <div className="mobile-menu-container">
          <Hamburger
            toggled={isMenuOpen}
            toggle={setIsMenuOpen}
            duration={0.4}
            color={hamburgerColor}
            easing="ease-in-out"
          />
          <div
            className={`nav-mobile-menu ${
              isMenuOpen ? "nav-mobile-menu-open" : ""
            }`}
          >
            <NavLinkList onClick={handleMenuClick} isLoggedIn={isLoggedIn} />
          </div>
        </div>
      </nav>
      <div
        className={`nav-mobile-menu-dim-background ${
          isMenuOpen ? "nav-mobile-menu-dim-background-menu-open" : ""
        }`}
        onClick={handleMenuClick}
      ></div>
    </div>
  );
};
