import React, { useState, useRef, useEffect, useCallback } from "react";
import { API } from "../lib/api";
import "./loginCode.css";

interface LoginCodeFormProps {
  email: string;
  setIsLoggedInState: (loggedIn: boolean) => void;
}

interface ApiResponse {
  success: boolean;
  message?: string;
}

const useCodeInput = (length: number) => {
  const [code, setCode] = useState<string[]>(Array(length).fill(""));
  const inputs = useRef<(HTMLInputElement | null)[]>([]);

  const handleChange = useCallback((index: number, value: string) => {
    if (/^\d*$/.test(value) && value.length <= 1) {
      setCode(prev => {
        const newCode = [...prev];
        newCode[index] = value;
        return newCode;
      });

      if (value && index < length - 1) {
        inputs.current[index + 1]?.focus();
      }
    }
  }, [length]);

  const handleKeyDown = useCallback((index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Backspace" && !code[index] && index > 0) {
      inputs.current[index - 1]?.focus();
    }
  }, [code]);

  return { code, inputs, handleChange, handleKeyDown };
};

const LoginCodeForm: React.FC<LoginCodeFormProps> = ({ email, setIsLoggedInState }) => {
  const { code, inputs, handleChange, handleKeyDown } = useCodeInput(6);
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const handleSubmit = useCallback(async () => {
    const fullCode = code.join("");
    if (fullCode.length === 6) {
      setIsLoading(true);
      try {
        const api = new API();
        const success: boolean = await api.login(email, fullCode);
        if (success) {
          setShowSuccess(true);
          setTimeout(() => setIsLoggedInState(true), 1000);
        } else {
          throw new Error("Invalid code. Please try again.");
        }
      } catch (error) {
        setError(error instanceof Error ? error : new Error("An unexpected error occurred."));
      } finally {
        setIsLoading(false);
      }
    } else {
      setError(new Error("Code must be 6 digits long."));
    }
  }, [code, email, setIsLoggedInState]);

  useEffect(() => {
    if (code.every(digit => digit !== "")) {
      handleSubmit();
    }
  }, [code, handleSubmit]);

  const handleResendCode = useCallback(() => {
    // Implement resend code functionality
  }, []);

  if (showSuccess) {
    return (
      <div className="login-code-form">
        <div className="login-code-form__success">Login Success!</div>
      </div>);
  }

  return (
    <div className="login-code-form">
      <form onSubmit={(event) => event.preventDefault()}>
        <label className="login-code-form__label">
          Enter the 6-digit code sent to {email}
        </label>
        {error && <div className="login-code-form__error">{error.message}</div>}
        <div className="login-code-form__input-container">
          {code.map((digit, index) => (
            <input
              key={index}
              ref={el => inputs.current[index] = el}
              type="text"
              inputMode="numeric"
              value={digit}
              onChange={(e) => handleChange(index, e.target.value)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              maxLength={1}
              className="login-code-form__input"
              aria-label={`Digit ${index + 1}`}
              autoComplete="one-time-code"
              disabled={isLoading}
            />
          ))}
        </div>
        {isLoading && <div className="login-code-form__loading">Verifying...</div>}
      </form>
    </div>
  );
};

export default LoginCodeForm;