import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface WeekCount {
  weekStart: string;
  count: number;
}

const formatLabel = (dateString: string): string => {
  const options = {
    month: "short" as const,
    day: "numeric" as const,
    year: "numeric" as const,
  };
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", options);
};

function groupByWeek(timestamps: number[]): WeekCount[] {
  if (timestamps.length === 0) {
    return [];
  }

  // Sort timestamps
  timestamps.sort((a, b) => a - b);

  const result: WeekCount[] = [];
  let currentWeekStart = new Date(timestamps[0]);
  currentWeekStart.setHours(0, 0, 0, 0); // Start of the day
  currentWeekStart.setDate(
    currentWeekStart.getDate() - currentWeekStart.getDay()
  ); // Start of the week

  let nextWeekStart = new Date(currentWeekStart);
  nextWeekStart.setDate(currentWeekStart.getDate() + 7);

  let count = 0;

  timestamps.forEach((timestamp) => {
    const date = new Date(timestamp);

    while (date >= nextWeekStart) {
      // Add the current week's count
      result.push({
        weekStart: currentWeekStart.toISOString().split("T")[0],
        count,
      });

      // Move to next week
      currentWeekStart = new Date(nextWeekStart);
      nextWeekStart.setDate(nextWeekStart.getDate() + 7);

      // Reset count for the new week
      count = 0;
    }

    count++;
  });

  // Add the last week
  result.push({
    weekStart: currentWeekStart.toISOString().split("T")[0],
    count,
  });

  return result;
}

const WeeklyContributionsChart = ({ contributions }) => {
  const [chartColors, setChartColors] = useState({
    textColor: "#ffffff",
    columnColor: "#ffffff",
    lineColor: "#ffffff",
  });

  useEffect(() => {
    const updateColors = () => {
      const root = getComputedStyle(document.documentElement);
      setChartColors({
        textColor: root.getPropertyValue("--main-color").trim(),
        columnColor: root.getPropertyValue("--accent-color").trim(),
        lineColor: root.getPropertyValue("--bg-color").trim(),
      });
    };

    updateColors(); // Initial update

    const colorSchemeQuery = window.matchMedia("(prefers-color-scheme: dark)");
    colorSchemeQuery.addEventListener("change", updateColors); // Update on color scheme change

    return () => {
      colorSchemeQuery.removeEventListener("change", updateColors); // Clean up
    };
  }, []);

  if (!contributions || contributions.length === 0) {
    return <p>No weekly contributions to display.</p>;
  }

  // Process contributions to get weekly counts
  const weeklyCounts = groupByWeek(contributions);

  // Extract labels and counts for the chart
  const labels = weeklyCounts.map((wc) => {
    const weekStartFormatted = formatLabel(wc.weekStart);
    return weekStartFormatted;
    // These are too big of labels
    const weekEnd = new Date(wc.weekStart);
    weekEnd.setDate(weekEnd.getDate() + 6); // Get the end date of the week
    const weekEndFormatted = formatLabel(weekEnd.toISOString().split("T")[0]);
    return `${weekStartFormatted} - ${weekEndFormatted}`;
  });
  const counts = weeklyCounts.map((wc) => wc.count);

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Weekly Entries",
        data: counts,
        backgroundColor: chartColors.columnColor,
        borderColor: "rgba(255, 255, 255, 0)",
        borderWidth: 0,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
          autoSkip: true,
          color: chartColors.textColor,
          maxRotation: 60,
          minRotation: 60,
        },
        borderWidth: 0,
      },
      y: {
        grid: {
          display: false,
        },
        beginAtZero: true,
        borderWidth: 0,
        border: {
          display: false,
        },
        ticks: {
          color: chartColors.textColor,
        },
        title: {
          display: false,
          text: "Weekly Entries",
          color: chartColors.textColor,
        },
      },
    },
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
  };

  return (
    <>
      <h3 style={{ textAlign: "center" }}>Weekly Entries</h3>
      <div style={{ height: "400px" }}>
        <Bar data={data} options={options} />
      </div>
    </>
  );
};

export default WeeklyContributionsChart;
