import React from "react";
import "./splash.css";
import LoginForm from "../components/loginForm";

export const Splash: React.FC<{ setIsLoggedInState: (loggedIn: boolean) => void }> = ({ setIsLoggedInState }) => {
  return (
    <div id="splash-wrapper">
      <div className="tagline">
        Your Story,
        <br />
        <span className="tagline-accent">Unscripted.</span>
      </div>

      <div className="subtagline">
        Simple and secure append-only journaling.
        <br />
        Discover your present self.
      </div>
      <LoginForm setIsLoggedInState={setIsLoggedInState} />
      <div className="spacer" />
    </div>
  );
};
