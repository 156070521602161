import React, { useState, useEffect } from "react";
import { ProfileResponse } from "../../models/profileResponse";
import LoadingMessage from "./loadingMessage";
import DateString from "./dateString";
import { Link, useNavigate } from "react-router-dom";
import Button from "./button";
import WeeklyContributionsChart from "./weeklyContributionsChart";

const Logout: React.FC<Props> = ({ onLogout }) => {
  const navigate = useNavigate();

  const handleLogout = async (onLogout) => {
    try {
      const response = await fetch("/api/logout");
      if (response.ok) {
        console.log("Successfully logged out!");
        await onLogout();
        navigate("/");
      } else {
        console.log("Error logging out!");
        console.log(response);
      }
    } catch (error) {
      console.log("Error logging out!");
      console.log(error);
    }
  };

  return <Button onClick={() => handleLogout(onLogout)}>Log Out</Button>;
};

interface Props {
  onLogout: () => Promise<void>;
}
const UserInfoTable: React.FC<Props> = ({ onLogout }) => {
  const [profileResponse, setProfileResponse] =
    useState<ProfileResponse | null>(null);

  useEffect(() => {
    fetch("/api/profile")
      .then((res) => res.json())
      .then((data) => setProfileResponse(data))
      .catch((error) => console.error(error));
  }, []);

  if (!profileResponse) {
    return <LoadingMessage />;
  }
  const userInfo = profileResponse.userInfo;
  if (userInfo.entryCount === 0 && userInfo.wordCount === 0) {
    return (
      <div>
        <p>
          Your email is <i className="accent">{userInfo.email}</i>
        </p>
        <Logout onLogout={onLogout} />
        <p>
          You've only just begun <i>Chronofiling</i>! Visit this page after you
          start writing to see some fun stats about your progress 🤓
        </p>
      </div>
    );
  }
  // continues

  let daysSection = <></>;
  if (
    Math.floor((Date.now() - userInfo.createdOn) / (1000 * 60 * 60 * 24)) == 0
  ) {
    daysSection = (
      <>
        Welcome to <i>Chronofile</i>!
      </>
    );
  } else {
    daysSection = (
      <>
        That's a total of{" "}
        <i className="accent">
          {Math.floor(
            (Date.now() - userInfo.createdOn) / (1000 * 60 * 60 * 24)
          )}{" "}
          days!
        </i>
      </>
    );
  }

  return (
    <div>
      <p>
        Your email is <i className="accent">{userInfo.email}</i>
      </p>
      <Logout onLogout={onLogout} />
      <p>
        You've been Chronofiling since{" "}
        <i>
          <DateString timestamp={userInfo.createdOn} className="accent" />.
        </i>{" "}
        {daysSection}
      </p>
      {(userInfo.entryCount > 0 || userInfo.wordCount > 0) && (
        <>
          <p>
            To date, you've written{" "}
            <i className="accent">{userInfo.wordCount} words</i> across{" "}
            <i className="accent">{userInfo.entryCount} entries.</i>
          </p>
          <WeeklyContributionsChart contributions={profileResponse.contributionTimes} />
        </>
      )}
    </div>
  );
};

export default UserInfoTable;
