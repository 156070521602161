import React from "react";
import { NotesManager } from "../components/notesManager";


export function Notes() {
  return (
    <div className="notes-wrapper">
      <NotesManager key={"b"}/>
    </div>
  );
}
