import React, { useEffect, useState } from "react";
import { Note } from "../../models/note";
import LoadingMessage from "./loadingMessage";
import NoteEntry from "./noteEntry";

interface Props {
  notes: Note[];
}

function NotesList(props: Props) {
  return (
    <div>
      {props.notes
        .slice(0)
        .reverse()
        .map((note, i) => (
          <NoteEntry key={i} note={note} />
        ))}
    </div>
  );
}

export default NotesList;
