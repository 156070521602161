import React from "react";
import UserInfoTable from "../components/userInfo";

interface Props {
  onLogout: () => Promise<void>;
}

export function Profile({ onLogout }: Props): JSX.Element {
  return (
    <div>
      <UserInfoTable onLogout={onLogout} />
    </div>
  );
}
