import React from "react";
import { Link } from "react-router-dom";

export function Error() {
  return (
    <div>
      <div>This is not a valid page :/</div>
      <Link to="/">Return home?</Link>
    </div>
  );
};
