import React from "react";
import { Config } from "../config";
import { Note } from "../../models/note";
import DateString from "./dateString";

import "./noteEntry.css";

interface Props {
  note: Note;
}

function splitStringIntoDivs(str) {
  const lines = str.split("\n");
  const divs = lines.map((line, i) => {
    const uniqueId = `line-${i}`;
    return (
      <div key={uniqueId} id={uniqueId}>
        {line}
        <br />
      </div>
    );
  });
  return divs;
}

const NoteEntry: React.FC<Props> = ({ note }) => {
  let content = splitStringIntoDivs(note.content);
  return (
    <>
      <div className="note-wrapper">
        <DateString className="note-date" timestamp={note.timestamp} />
        <div className="note-content">{content}</div>
      </div>
    </>
  );
};

export default NoteEntry;
