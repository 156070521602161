import React from "react";
import { useEffect, useState } from "react";
import { Routes, Route, Outlet, Link } from "react-router-dom";
import { About } from "./routes/about";
import { Notes } from "./routes/notes";
import { Error } from "./routes/error";
import { Splash } from "./routes/splash";
import { Layout } from "./routes/layout";
import { getApiObject, ApiContext } from "./lib/api";
import { Profile } from "./routes/profile";
import { Nav } from "./components/nav";

import "./app.css";

const App: React.FC = () => {
  const api = getApiObject();
  const [isLoggedInState, setIsLoggedInState] = useState(false);
  const [loadedOn, setLoadedOn] = useState<null | number>(null);

  const onLogout = async () => {
    setIsLoggedInState(false);
  };

  useEffect(() => {
    console.log("hello from app.tsx")
    const checkIsLoggedIn = async () => {
      setIsLoggedInState(await api.isLoggedIn());

      // Add a minimum 0.1-second delay before setting loadedOn
      setTimeout(() => {
        setLoadedOn(new Date().getTime());
      }, 100);
    };
    checkIsLoggedIn();
  }, []);

  if (isLoggedInState) {
    return (
      <div className={`app-wrapper${loadedOn ? " loaded" : ""}`}>
        <ApiContext.Provider value={api}>
          {loadedOn ? (
            <>
              <Nav isLoggedIn={isLoggedInState} />
              <div className="routes-wrapper">
                <Routes>
                  <Route
                    path="/"
                    element={<Layout loggedIn={isLoggedInState} />}
                  >
                    <>
                      <Route key={"notes"} index element={<Notes />} />
                      <Route key={"about"} path="/about" element={<About />} />
                      <Route
                        key={"profile"}
                        path="/profile"
                        element={<Profile onLogout={onLogout} />}
                      />
                    </>
                    <Route key={"about"} index element={<About />} />

                    <Route key={"*"} path="*" element={<Error />} />
                  </Route>
                </Routes>
              </div>
            </>
          ) : null}
          <div className={`black-screen${loadedOn ? " hidden" : ""}`}></div>
        </ApiContext.Provider>
      </div>
    );
  } else {
    return (
      <div className="app-wrapper">
        <Nav isLoggedIn={isLoggedInState} />
        <div className="routes-wrapper">
          <Routes>
            <Route path="/" element={<Splash setIsLoggedInState={setIsLoggedInState}/>} />
            <Route key={"about"} path="/about" element={<About />} />
            <Route key={"*"} path="*" element={<Error />} />
          </Routes>
        </div>
      </div>
    );
  }
};

export default App;
