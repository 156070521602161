import React, {
  useRef,
  useState,
  useEffect,
  RefObject,
  useContext,
} from "react";
import { Note } from "../../models/note";
import { API, ApiContext } from "../lib/api";
import NotesList from "./notesList";
import LoadingMessage from "./loadingMessage";
import LoadFailed from "./loadFailed";
import NoteEditor from "./noteEditor";
import WelcomeNote from "./welcomeNote";

import "./notesManager.css";

export const NotesManager = () => {
  const [currentDate, setCurrentDate] = useState<number>(new Date().getTime());
  const [previousEntries, setPreviousEntries] = useState([] as Note[]);
  const [loadedOn, setLoadedOn] = useState<null | number>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadFailed, setIsLoadFailed] = useState<boolean>(false);
  const api: API = useContext(ApiContext);

  useEffect(() => {
    const fetchNotes = async () => {
      const timer = setTimeout(() => {
        setIsLoading(false);
        setIsLoadFailed(true);
      }, 10000);

      try {
        const notes = await api.loadNotes();
        if (!notes) {
          return;
        }
        setPreviousEntries((state) => {
          // Prefer the server's version
          let allNotes: Set<string> = new Set();
          state.forEach((x) => allNotes.add(JSON.stringify(x)));
          notes.forEach((x) => allNotes.add(JSON.stringify(x)));
          let newNotes = [...allNotes]
            .map((x) => JSON.parse(x))
            .sort((a, b) => a.id - b.id);
          return newNotes;
        });
        setLoadedOn(new Date().getTime());
        setIsLoading(false);
        setIsLoadFailed(false);
      } catch (error) {
        setIsLoading(false);
        setIsLoadFailed(true);
      }

      clearTimeout(timer);
    };

    fetchNotes();
  }, []);

  const save = async (content: string): Promise<boolean> => {
    let nextId: number =
      previousEntries.length == 0
        ? 0
        : previousEntries[previousEntries.length - 1].id + 1;
    let nextNote = {
      id: nextId,
      timestamp: currentDate,
      content: content,
    };

    try {
      if (!await api.sendNote(nextNote)) {
        return false;
      }
      setPreviousEntries((state) => {
        return [...state, nextNote];
      });
      setCurrentDate(new Date().getTime());
      return true;
    } catch (error) {
      return false;
    }
  };

  return (
    <div className="notes-manager-wrapper">
      <NoteEditor save={save} currentDate={currentDate} />
      {isLoading && <LoadingMessage />}
      {isLoadFailed && <LoadFailed />}
      {loadedOn !== null &&
        previousEntries.length === 0 &&
        !isLoading &&
        !isLoadFailed && <WelcomeNote />}
      {previousEntries.length > 0 && <NotesList notes={previousEntries} />}
    </div>
  );
};
