import React from 'react';
import './button.css';

const Button = ({ onClick, children, type }) => (
  <button className="theme-button shine-effect-background-hover" onClick={onClick} type={type}>
    {children}
  </button>
);

Button.defaultProps = {
  type: "button",
  onClick: undefined,
};

export default Button;
