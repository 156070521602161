import React from "react";
import NoteEntry from "./noteEntry";

const WelcomeNote: React.FC = () => {
  const message =
    "👋 Welcome to Chronofile! \n\n" +
    "📝 Chronofile is your digital journal for the information age. We are excited to help you get started. Here are a few things to keep in mind as you use Chronofile.\n\n" +
    "⚠️ We aim to empower you to capture the present moment. To that end, we do not support editing or deletion of old notes.\n\n" +
    "🕰️ Your thoughts will be organized into this feed (your chronofile) in reverse-chronological order.\n\n" +
    "📔 With Chronofile, you can write any type of entry - a personal journal entry, a to-do list, a dream, a goal, a conversation, a poem, or anything else that comes to mind. \n\n" +
    "⏳ As you continue to use Chronofile, we hope you'll take some time to reflect on your past entries and see how your thinking has evolved over time. \n\n" +
    "🔒 Your thoughts are yours. Chronofile stores your notes on a trusted cloud provider. Your data is encrypted over the network using HTTPS.\n\n" +
    "🧐 For more information, please visit the about page!\n\n" +
    "👻 Once you start using Chronofile this entry will disappear.\n\n" +
    "🎉 Happy Chronofiling!";

  return (
    <NoteEntry
      note={{
        id: 0,
        timestamp: new Date().getTime(),
        content: message,
      }}
    />
  );
};

export default WelcomeNote;
