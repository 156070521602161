import React from "react";
import { Note } from "../../models/note";

export class API {
  cache = {
    notes: null as Note[] | null,
    timestamp: 0,
  };
  ongoingRequest: Promise<Note[] | null> | null = null;

  constructor() {
    this.loadNotes();
  }

  async isLoggedIn(): Promise<boolean> {
    return (await this.loadNotes()) != null;
  }

 async login(email: string, code: string): Promise<boolean> {
  let res = await fetch(`/api/login?email=${email}&code=${code}`)
  return res.status == 200;
 }

  async getLoginCodeEmail(email: string): Promise<boolean> {
    let res = await fetch(`/api/login-code?email=${email}`);
    return res.status == 200;
  }

  async sendNote(note: Note): Promise<boolean> {
    const options = {
      method: "POST",
      body: JSON.stringify(note),
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch("/api/notes", options);
      if (await response.text() == "Note saved!") {
        return true;
      } else {
        alert(`Error sending note: ${response.status}`);
        return false;
      }
    } catch (error) {
      alert(`Error sending note: ${error}`);
      return false;
    }
  }
  async loadNotes(): Promise<Note[] | null> {
    const now = Date.now();
    const cacheDuration = 5000; // 5 seconds in milliseconds

    if (this.cache.notes && now - this.cache.timestamp < cacheDuration) {
      return this.cache.notes;
    }

    if (this.ongoingRequest) {
      return this.ongoingRequest;
    }

    const options = {
      method: "GET",
    };
    this.ongoingRequest = fetch("/api/notes", options)
      .then(async (res) => {
        if (res.status != 200) {
          return null;
        }
        this.cache.notes = (await res.json()) as Note[];
        this.cache.timestamp = now;
        return this.cache.notes;
      })
      .finally(() => {
        this.ongoingRequest = null;
      });

    return this.ongoingRequest;
  }
}

let api: null | API = null;
export const getApiObject = () => {
  if (!api) {
    api = new API();
  }
  return api;
};

export const ApiContext = React.createContext(getApiObject());
