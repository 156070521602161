import React from "react";
import { Outlet } from "react-router-dom";

interface Props {
  loggedIn: boolean;
}

export const Layout: React.FC<Props> = ({ loggedIn }) => {
    return (
      <>
        <Outlet />
      </>
    );
};
