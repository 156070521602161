import React from "react";
import "./about.css";
export const About: React.FC = () => {
  return (
    <div className="about-wrapper">
      <div className="faq-wrapper">
        <h3 className="faq-question">What is Chronofile?</h3>
        <div className="faq-answer">
          <p>
            Chronofile is the world's simplest digital journal.
          </p>
          <p>
            In a world obsessed with perfection and filled with noise,
            Chronofile celebrates the raw, the real, and the authentic. Welcome
            to the best place on the internet to write your thoughts down.
          </p>
        </div>
        <hr className="faq-divider" />
        <h3 className="faq-question">How do I use Chronofile?</h3>
        <div className="faq-answer">
          <p>Using Chronofile is a breeze. Here is how to get started:</p>
          <ol>
            <li>Login to Chronofile by submitting your email address.</li>
            <li>Look for the link in the email we'll send you.</li>
            <li>Record your thoughts...</li>
            <li>
              When you're done with your thought, press submit! (or ctrl+enter /
              cmd+return)
            </li>
          </ol>
          <p>Voila! You are now an expert user of Chronofile.</p>
        </div>
        <hr className="faq-divider" />
        <h3 className="faq-question">
          How do I edit or delete a previous entry?
        </h3>
        <div className="faq-answer">
          <p>
            Chronofile does not support editing previous entries. This is
            intentional.
          </p>
          <p>
            I created Chronofile as a repository for scattered thoughts. By not
            supporting edits or deletes, Chronofile offers a healthy challenge
            to my perfectionism and encourages me to just start writing.
          </p>
          <p>
            Made a mistake? Take a deep breath - you'll survive. This is the
            main ethos of Chronofile: imperfect records now are better than no
            records later. Over time I hope you'll come to think of your
            Chronofile as an expression of self in each present moment rather
            than an edited history of your past.
          </p>
        </div>
        <hr className="faq-divider" />
        <h3 className="faq-question">
          How do I organize my writings in Chronofile?
        </h3>
        <div className="faq-answer">
          <p>
            Chronofile is organized in reverse chronological order. Whenever you
            submit a new entry, it will show up at the top, pushing existing
            entries down.
          </p>
          <p>
            Beyond that, there are no organizational features. This is also
            intentional. Eliminating the worry of which folder to put something
            helps me write whatever it is down now.
          </p>
          <p>
            All the entries live in one place, so I know exactly where to look
            when I want to find one.
          </p>
        </div>
        <hr className="faq-divider" />
        <h3 className="faq-question">Why should I use Chronofile?</h3>
        <div className="faq-answer">
          <p>
            Chronofile is the repository for my everyday life. My scattered
            thoughts from throughout the day from the mundane to the existential
            live in my Chronofile.
          </p>
          <p>
            Writing down my thoughts helps me realize how I might develop them
            further.
          </p>
          <p>
            Writing down my anxieties helps me challenge them and see them from
            different perspectives.
          </p>
          <p>
            Writing down my stressors helps me recontextualize them against a
            wider backdrop: "Am I really going to be concerned about this in a
            week?"
          </p>
          <p>
            Writing down my gratitudes helps me foster a mindset of appreciation
            and cultivate a habit of recognizing and acknowledging the good
            things in my life.
          </p>
          <p>
            Writing down my memories satiates my nostalgia and helps me carry
            them forward.
          </p>
          <p>
            As life progresses, I intend for Chronofile to become a personal
            time machine, preserving my past, and helping me reflect on my life
            journey.
          </p>
          <p>
            I hope that you might be able to get some value out of this passion
            project of mine as well.
          </p>
        </div>
        <hr className="faq-divider" />
        <h3 className="faq-question">What inspired Chronofile?</h3>
        <div className="faq-answer">
          <p>
            Chronofile, this website, is a passion project. I created it out of
            personal neccessity for a singular repository of my thoughts. I also
            wanted to test my software and product skills.
          </p>
          <p>
            Chronofile's namesake is the{" "}
            <a href="https://en.wikipedia.org/wiki/Dymaxion_Chronofile">
              Dymaxion Chronofile
            </a>
            , which inspired this project in some ways as well.
          </p>
        </div>
        <hr className="faq-divider" />
        <h3 className="faq-question">Who are you?</h3>
        <div className="faq-answer">
          <p>
            Hi! I'm Thomas Fuller. You can view my personal website at{" "}
            <a href="https://tjf.lol">tjf.lol</a>.
          </p>
        </div>
      </div>
    </div>
  );
};
