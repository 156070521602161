import React, { useContext, useState } from "react";
import { API, ApiContext } from "../lib/api";
import "./loginForm.css";
import LoginCodeForm from "./loginCode";

const LoginForm : React.FC<{ setIsLoggedInState: (loggedIn: boolean) => void }> = ({ setIsLoggedInState }) => {
  const [email, setEmail] = useState("" as string);
  const [error, setError] = useState(null as string | null);
  const [success, setSuccess] = useState(false as boolean);
  const api: API = useContext(ApiContext);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let res = await api.getLoginCodeEmail(email);
      if (res) {
        setSuccess(true);
      } else {
        setError(
          "Something went wrong. Please try again."
        );
        console.log("Failed to send email");
      }
    } catch (error) {
      setError("Something went wrong. Please try again.");
      console.log(error);
    }
  };

  if (success) {
    return <LoginCodeForm email={email} setIsLoggedInState={setIsLoggedInState}/>;
  }

  return (
    <form className="login-form" onSubmit={handleSubmit}>
      {error && <div className="login-error-message">{error}</div>}
      <div className="input-wrapper">
        <input
          type="text"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          placeholder="email@example.com"
        />
        <button className="login-button shine-effect-background" type="submit">
          Login or Sign Up (Free)
        </button>
      </div>
    </form>
  );
};

export default LoginForm;
