import React, { useState, useEffect } from 'react';

export default function LoadingMessage() {
  const [dots, setDots] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((dots) => (dots.length >= 3 ? '' : dots + '.'));
    }, 250);
    return () => clearInterval(interval);
  }, []);

  return <p>Loading{dots}</p>;
}